import React, { useState, useEffect } from "react";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import rama from "../assets/ramar.jpg";
import subheader from "../assets/img/subheader.jpg";
import Header1 from "../Component/Header1";
import Carousals from "../assets/ram2.jpeg";
import Carousals1 from "../assets/ram1.jpeg";
import Load from "../assets/gif.gif";

function About() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} style={{ height: "200px" }}></img>
          </div>
        </>
      ) : (
        <>
          <Header1 />

          <div
            class="sigma_subheader dark-overlay dark-overlay-2 pb-4 "
            style={{ backgroundImage: `url(${subheader})` }}
          >
            <div class="container mt-5">
              <div class="sigma_subheader-inner">
                <div class="sigma_subheader-text">
                  <h1>About Us</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item mr-3">
                      <a class="btn-link">
                        <NavLink to="/">
                          <span style={{ color: "#911c1c" }}>Home</span>
                        </NavLink>{" "}
                      </a>
                    </li>
                    <MDBIcon icon="om" size="10px" className="mr-3 " />
                    <li class="breadcrumb-item active" aria-current="page">
                      <a class="btn-link">
                        <NavLink to="/about">
                          <span style={{ color: "#911c1c" }}>About Us</span>
                        </NavLink>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <section class="section mt-3" id="about_us">
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  About Us
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-6 mb-lg-30">
                  <div class="sigma_video-popup-wrap">
                    <img src={Carousals} alt="video" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="sigma_video-popup-wrap">
                    <img src={Carousals1} alt="video" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="conainer">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <h1 class="subtitle">Jai Sriram Jai Jai Sriram</h1>
                  <div class="data_more_less">
                    <div
                      class="data_more_less_inner"
                      data-height="500"
                      data-increase-by="400"
                    >
                      <div class="data_more_less_body">
                        <p class="blockquote" style={{color:"#3D5E95"}}>
                          Prince of Ayodhya: Rama was born to King Dasharatha
                          and Queen Kaushalya in the city of Ayodhya. He is
                          often referred to as "Maryada Purushottama," which
                          means the perfect or ideal man who strictly adheres to
                          dharma (righteousness). Exemplary Virtues: Rama is
                          celebrated for his impeccable character and adherence
                          to moral and ethical principles. His life is often
                          considered a model for righteous living, and he is
                          revered as an embodiment of virtue, humility, and
                          compassion. Heroic Exploits: The most well-known
                          episode from Rama's life is his quest to rescue his
                          wife, Sita, who was abducted by the demon king Ravana.
                          This forms the central narrative of the Ramayana,
                          showcasing Rama's bravery, intelligence, and
                          unwavering commitment to duty. Divine Bow: Rama is
                          known for breaking the divine bow of Lord Shiva, a
                          feat that earned him the right to marry Sita. This
                          event is a significant moment in the Ramayana and
                          symbolizes Rama's strength and divine favor. Ideal
                          King: After successfully completing his exile and
                          rescuing Sita, Rama returns to Ayodhya and is crowned
                          as the king. He is often regarded as the epitome of an
                          ideal ruler, emphasizing dharma, justice, and the
                          welfare of his subjects. Loyal Companions: Rama is
                          accompanied by his loyal allies, including his devoted
                          wife Sita, his loyal half-brother Lakshmana, and the
                          monkey-god Hanuman. These characters play crucial
                          roles in the epic and contribute to the overall
                          narrative. Devotion to Lord Rama: The worship of Rama
                          is a significant aspect of Hindu tradition, and
                          devotees often recite the 'Rama Nama' or the name of
                          Rama as a form of prayer. The chanting of the mantra
                          "Sri Rama Jai Rama Jai Jai Rama" is common in
                          devotion.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <img src={rama} alt="" />
                </div>
              </div>
            </div>
          </section>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}

export default About;
