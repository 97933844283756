import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../assets/logoes.png";

const Header1 = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar
        expand="lg"
        className="fixed-top bg-white"
        style={{ borderBottom: "1px solid #911c1c" }}
      >
        <div style={{ width: "45%" }}>
          <Navbar.Brand href="#">
            {" "}
            <a class="navbar-brand">
              <NavLink active to="/">
                <img src={logo} alt="logo" />
              </NavLink>
            </a>
          </Navbar.Brand>
        </div>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link href="#action1">
              <a>
                <NavLink to="/">HOME</NavLink>
              </a>
            </Nav.Link>
            <Nav.Link href="#action2">
              <a>
                <NavLink to="/about">ABOUT US</NavLink>
              </a>
            </Nav.Link>
            <Nav.Link href="#action2">
              <a>
                <NavLink to="/gallery">GALLERY</NavLink>
              </a>
            </Nav.Link>
            <Nav.Link href="#action2">
              <a>
                <NavLink to="/festival">FESTIVALS</NavLink>
              </a>
            </Nav.Link>

            <Nav.Link href="#">
              <NavLink to="/contact-us" className="list">
                CONTACT US
              </NavLink>{" "}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header1;
