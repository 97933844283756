import React, { useEffect } from "react";
import logo from "../assets/logoes.png";

import { NavLink } from "react-router-dom";

const Footer = () => {
  // window.addEventListener("scroll", (e) => {
  //   var el = document.getElementById("jsScroll");
  //   if (window.scrollY > 200) {
  //     el.classList.add("visible");
  //   } else {
  //     el.classList.remove("visible");
  //   }
  // });

  return (
    <div>
      <footer class="sigma_footer footer- 2">
        <div class="sigma_footer-middle">
          <div class="container">
            <div class="row">
              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-widget"
                id="ldds"
              >
                <img src={logo} alt="logo" style={{ height: "50px" }} />

                <h5 class="widget-title mt-4" id="ldds">
                  Brahmas Dharamasala Foundation
                </h5>
                <p class="mb-4" id="ldds">
                  Many temples and their associated foundations have official
                  websites that provide information about their history,
                  activities, and services. Check if there's an official website
                  for the "Brahmas Dharamasala Foundation."
                </p>
              </div>
              <div class="col-xl-4  col-md-4  col-sm-12 footer-widget">
                <h5 class="widget-title text-center">Links</h5>

                {/* <div className="col-md-12 ">
                </div> */}

                <ul>
                  <li style={{ color: "black" }} className="text-center">
                    <NavLink to="/" style={{ padding: "0px" }}>
                      HOME
                    </NavLink>
                  </li>
                  <li style={{ color: "black" }} className="text-center">
                    <NavLink to="/about" style={{ padding: "0px" }}>
                      ABOUT{" "}
                    </NavLink>
                  </li>

                  <li style={{ color: "black" }} className="text-center">
                    <NavLink to="/gallery" style={{ padding: "0px" }}>
                      GALLERY
                    </NavLink>
                  </li>
                  <li style={{ color: "black" }} className="text-center">
                    <NavLink to="/festival" style={{ padding: "0px" }}>
                      FESTIVALS
                    </NavLink>
                  </li>
                  <li style={{ color: "black" }} className="text-center mb-4">
                    <NavLink to="/contact-us" style={{ padding: "0px" }}>
                      {" "}
                      CONTACT US
                    </NavLink>
                  </li>
                </ul>

                {/* <div className="col-md-6">
                    <ul>
                    </ul>
                  </div> */}
              </div>

              <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12  footer-widget widget-recent-posts ">
                <h5 class="widget-title" id="ldds">
                  Address
                </h5>

                <div class="" id="ldd">
                  <i class="fa fa-phone custom-primary me-3"></i>
                  <a href="tel:+91 9494613601">
                    <span id="ldd">+91 9494613601</span>
                  </a>
                </div>
                <div class="  mt-2" id="ldd">
                  <i class="far fa-envelope custom-primary me-3"></i>
                  <a href="mailto:info@digitalraiz.com">
                    <span id="ldd">info@digitalraiz.com</span>
                  </a>
                </div>
                <div class="  mt-2 mobile_mail" id="ldd">
                  <i class="far fa-map custom-primary me-3 mb-3"></i>
                  <span id="ldd">
                    Uppalapadu , Dagadarthi, Nellore - 522509.
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="container ml-3">
                  <div class="bottm_footer">
                    <div class="sigma_footer-copyright form-group">
                      <p className="text-center">
                        <a href="https://www.digitalraiz.com/" target="_blank">
                          <span>Design & Developed by </span>
                          DIGITALRAIZ CREATIVE SOLUTIONS PVT LTD
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
