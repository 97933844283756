import React, { useState, useEffect } from "react";
import Footer from "../Component/Footer";
import Carousal1 from "../assets/img/banner/banner2.jpg";
import Carousal4 from "../assets/img/banner/banner4.jpg";
import Carousal5 from "../assets/img/banner/banner5.jpg";
import { NavLink } from "react-router-dom";
import subheader from "../assets/img/subheader.jpg";
import { MDBIcon } from "mdbreact";
import Header1 from "../Component/Header1";
import Load from "../assets/gif.gif";

import temp1 from "../assets/temp5.jpg";
import temp2 from "../assets/temp4.jpg";
import temp3 from "../assets/temp3.jpg";

const Projucts = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} style={{ height: "200px" }}></img>
          </div>
        </>
      ) : (
        <>
          <Header1 />

          <div
            class="sigma_subheader dark-overlay dark-overlay-2 pb-4 mb-4"
            style={{ backgroundImage: `url(${subheader})` }}
          >
            <div class="container mt-5">
              <div class="sigma_subheader-inner">
                <div class="sigma_subheader-text">
                  <h1>Gallery</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item mr-3">
                      <a class="btn-link">
                        <NavLink to="/">
                          <span style={{ color: "#911c1c" }}>Home</span>
                        </NavLink>{" "}
                      </a>
                    </li>
                    <MDBIcon icon="om" size="10px" className="mr-3 " />
                    <li class="breadcrumb-item active" aria-current="page">
                      <a class="btn-link">
                        <NavLink to="/gallery">
                          <span style={{ color: "#911c1c" }}>Gallery</span>
                        </NavLink>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div class="section section-padding">
            <div class="container">
            <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  Gallery
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="portfolio-filter row">
                <div class="col-lg-4 coaching">
                  <div class="sigma_portfolio-item style-2">
                    <img
                      src={temp1}
                      alt="portfolio"
                      style={{ height: "240px" }}
                    />
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{ color: "#3D5E95" }}>
                            Parasuraamaavataram{" "}
                          </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon
                          icon="arrow-right"
                          style={{ color: "#3D5E95" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 strategy">
                  <div class="sigma_portfolio-item style-2">
                    <img
                      src={temp2}
                      alt="portfolio"
                      style={{ height: "240px" }}
                    />
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{ color: "#3D5E95" }}>
                            {" "}
                            Varaahaavataram{" "}
                          </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon
                          icon="arrow-right"
                          style={{ color: "#3D5E95" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 coaching strategy">
                  <div class="sigma_portfolio-item style-2">
                    <img
                      src={temp3}
                      alt="portfolio"
                      style={{ height: "240px" }}
                    />
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{ color: "#3D5E95" }}>
                            Sree rama navami kalyanam{" "}
                          </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon
                          icon="arrow-right"
                          style={{ color: "#3D5E95" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Projucts;
