import React from "react";
import Header from "../Component/Header";
import Navbar from "../Component/Navbar";
// import Footer from "../Component/Footer";
import Carousal1 from "../assets/img/banner/banner2.jpg";
import Carousal4 from "../assets/img/banner/banner4.jpg";
import Carousal5 from "../assets/img/banner/banner5.jpg";
import logo from "../assets/img/logo/logo.png";
import maharastra from "../assets/img/temples/maharastra.jpg";
import ayodya from "../assets/img/temples/ayodya-temple.jpg";
import amrutaser from "../assets/img/temples/amrutaser.jpg";
import bg1 from "../assets/img/bg1.jpg";
import { Link, NavLink } from "react-router-dom";
import subheader from "../assets/img/subheader.jpg";
import { MDBIcon } from "mdbreact";

const Temples = () => {
  return (
    <>
      <Header />
      {/* <Navbar /> */}
      <div
        class="sigma_subheader dark-overlay dark-overlay-2 pb-4 mb-4"
        style={{ backgroundImage: `url(${subheader})` }}
      >
        <div class="Temples">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>Temples</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item mr-3">
                  <a class="btn-link">
                    <NavLink to="/">
                      <span style={{ color: "#911c1c" }}>Home</span>
                    </NavLink>{" "}
                  </a>
                </li>
                <MDBIcon icon="om" size="10px" className="mr-3 " />
                <li class="breadcrumb-item active" aria-current="page">
                  <a class="btn-link">
                    <NavLink to="/temples">
                      <span style={{ color: "#911c1c" }}>Temples</span>
                    </NavLink>
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* <div class="container">
                <div class="section-title text-center">
                    <p class="subtitle">Services - Details</p>
                </div>
                <div class="row">
                    <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
                        <div class="feature-main">
                            <div class="feature-box">
                                <img src="assets/img/temples/amrutaser.jpg" class="img-responsive" alt="" />
                                <div class="cover"></div>
                            </div>
                            <div class="feature-head">
                                <div class="tample_title">
                                    <h3>Amrutaser Temple</h3>
                                    <span class="date_info"><i class="fa fa-calendar" aria-hidden="true"></i>22-12-2021</span>
                                </div>
                                <p>cupidatat skateboard dolor brunch food truck quinoaew nesciunt laborum eiusmod brunch.
                                </p>
                                <div class="card_bottom">
                                    <a href="#"> Status <i class="fa fa-angle-double-right"></i> <span>Pending</span></a>
                                    <div class="edit_delate">
                                        <span><a href="#"></a></span>
                                     <span><a href="#"></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div> */}
      <div class="feature section-padding copntact_us_padding">
        <div class="container">
          <div class="section-title text-center">
            <p class="subtitle">
              <MDBIcon icon="om" size="10px" className="mr-3 " />
              Services - Details
              <MDBIcon icon="om" size="1x" className="ml-3" />
            </p>
          </div>
          <div class="row">
            <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
              <div class="feature-main">
                <div class="feature-box">
                  <img src={Carousal1} class="img-responsive" alt="" />
                  <div class="cover"></div>
                </div>
                <div class="feature-head">
                  <div class="tample_title">
                    <h3>Amrutaser Temple</h3>
                    <span class="date_info">
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      22-12-2021
                    </span>
                  </div>
                  <p>
                    cupidatat skateboard dolor brunch food truck quinoaew
                    nesciunt laborum eiusmod brunch.
                  </p>
                  <div class="card_bottom">
                    <a href="#">
                      {" "}
                      Status <i class="fa fa-angle-double-right"></i>{" "}
                      <span>Pending</span>
                    </a>
                    <div class="edit_delate">
                      <span>
                        <a href="#">
                          <MDBIcon icon="pencil-alt" />
                        </a>
                      </span>
                      <span>
                        <a href="#">
                          <MDBIcon icon="trash-alt" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
              <div class="feature-main">
                <div class="feature-box">
                  <img src={Carousal5} class="img-responsive" alt="" />
                  <div class="cover"></div>
                </div>
                <div class="feature-head">
                  <div class="tample_title">
                    <h3>Amrutaser Temple</h3>
                    <span class="date_info">
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      22-12-2021
                    </span>
                  </div>
                  <p>
                    cupidatat skateboard dolor brunch food truck quinoaew
                    nesciunt laborum eiusmod brunch.
                  </p>
                  <div class="card_bottom">
                    <a href="#">
                      {" "}
                      Status <i class="fa fa-angle-double-right"></i>{" "}
                      <span>Pending</span>
                    </a>
                    <div class="edit_delate">
                      <span>
                        <a href="#">
                          <MDBIcon icon="pencil-alt" />
                        </a>
                      </span>
                      <span>
                        <a href="#">
                          <MDBIcon icon="trash-alt" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-4 col-lg-4">
              <div class="feature-main">
                <div class="feature-box">
                  <img src={Carousal4} class="img-responsive" alt="" />
                  <div class="cover"></div>
                </div>
                <div class="feature-head">
                  <div class="tample_title">
                    <h3>Amrutaser Temple</h3>
                    <span class="date_info">
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      22-12-2021
                    </span>
                  </div>
                  <p>
                    cupidatat skateboard dolor brunch food truck quinoaew
                    nesciunt laborum eiusmod brunch.
                  </p>
                  <div class="card_bottom">
                    <a href="#">
                      {" "}
                      Status <i class="fa fa-angle-double-right"></i>{" "}
                      <span>Pending</span>
                    </a>
                    <div class="edit_delate">
                      <span>
                        <a href="#">
                          <MDBIcon icon="pencil-alt" />
                        </a>
                      </span>
                      <span>
                        <a href="#">
                          <MDBIcon icon="trash-alt" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="sigma_footer footer- 2">
        <div class="sigma_footer-middle">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer-widget">
                <h5 class="widget-title">Jai SriRam</h5>
                <p class="mb-4">
                  Jai Sriram TV is a platform for Indian across the globe giving
                  news and views which spreads like air in the environment using
                  technology at par with others in the field of Journalism. We
                  reflect the views of common man, ordinary citizens on a global
                  platform.
                </p>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center">
                  <i class="far fa-phone custom-primary me-3"></i>
                  <a href="tel:+91 9494613601">
                    <span>+91 9494613601</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                  <i class="far fa-envelope custom-primary me-3"></i>
                  <a href="mailto:info@brahmasdharamasalafoundation">
                    <span>info@brahmasdharamasalafoundation</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2 mobile_mail">
                  <i class="far fa-map-marker custom-primary me-3"></i>
                  <span>
                    Uppalapadu , Dagadarthi, Nellore - 522509.
                  </span>
                </div>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12 footer-widget">
                <h5 class="widget-title">Links</h5>
                {/* <ul>
           <li class="active"> <a>Home</a> </li>
           <li> <a href="about-us.html">About Us</a> </li>
           <li> <a href="project.html">Projects</a> </li>
           <li> <a href="services.html">Services</a> </li>
           <li> <a href="contact-us.html">Contact Us</a> </li>
           <li> <a href="broadcast.html">broadcast</a> </li>
         </ul> */}
                <ul>
                  <li class="active">
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">ABOUT US</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects">PROJUCTS</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">SERVICES</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">CONTACT US</NavLink>
                  </li>
                </ul>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
                <h5 class="widget-title">Recent Projects</h5>
                <article class="sigma_recent-post">
                  <a>
                    <img src={maharastra} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>
                          Temple companies are being so transparent with their
                          work
                        </span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
                <article class="sigma_recent-post">
                  <a>
                    <img src={ayodya} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>Testimony love offering so blessed</span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
                <article class="sigma_recent-post">
                  <a>
                    <img src={amrutaser} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>
                          As we've all discovered by now, the world can change
                        </span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="bottm_footer">
                    <div class="sigma_footer-copyright">
                      <p>
                        <a
                          href="https://www.digitalraiz.com/"
                          target="_blank"
                          class="custom-primary"
                        >
                          <span>Copyright © 2021</span>
                          DigitalRaiz <span>All Rights Reserved</span>
                        </a>{" "}
                      </p>
                    </div>
                    <div
                      class="sigma_footer-logo"
                      style={{ marginLeft: "10rem" }}
                    >
                      <img src={logo} alt="logo" />
                    </div>
                    <ul class="sigma_sm square" style={{ marginLeft: "15rem" }}>
                      <li>
                        <a href="https://www.facebook.com/" target="_blank">
                          <MDBIcon fab icon="facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/" target="_blank">
                          <MDBIcon fab icon="linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/home" target="_blank">
                          <MDBIcon fab icon="twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/" target="_blank">
                          <MDBIcon fab icon="youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="jsScroll" class="scroll" onclick={()=>scrollTo()}>
   <MDBIcon icon="angle-up" />
   </div> */}
        </div>
      </footer>
    </>
  );
};

export default Temples;
