import React, { useState, useEffect } from "react";
import Homeslider1 from "../Component/Homeslider1";
import Footer from "../Component/Footer";
import Service from "../Component/Service";
import "../css/Common.css";
import Carousals from "../assets/ram2.jpeg";
import Carousals1 from "../assets/ram1.jpeg";
import { NavLink } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import rama from "../assets/ramar.jpg";
import Header1 from "../Component/Header1";
import Load from "../assets/gif.gif";
import Modal from "react-bootstrap/Modal";
import Carousalss from "../assets/ram1.jpeg";
import Carousals5 from "../assets/ram2.jpeg";
import { Row, Col } from "react-bootstrap";
import temp1 from "../assets/temp5.jpg";
import temp2 from "../assets/temp4.jpg";
import temp3 from "../assets/temp3.jpg";
import temple from "../assets/img/new/temple.png";
import hindu from "../assets/img/new/hindu.png";
import pooja from "../assets/img/new/pooja.png";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import bg1 from "../assets/img/bg1.jpg";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [lgShow, setLgShow] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);

    const timers = setInterval(() => {
      setLgShow(true);
    }, 160000);
    return () => clearInterval(timer, timers);
  }, []);

  // const scrollTo = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // useEffect(() => {
  //   loadCaptchaEnginge(8);
  // }, []);

  // const doSubmit = () => {
  //   let user_captcha = document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha) === true) {
  //     alert("Captcha Matched");
  //     loadCaptchaEnginge(6);
  //     document.getElementById("user_captcha_input").value = "";
  //   } else {
  //     alert("Captcha Does Not Match");
  //     document.getElementById("user_captcha_input").value = "";
  //   }
  // };

 

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} style={{ height: "200px" }}></img>
          </div>
        </>
      ) : (
        <>
          <Header1 />
          <Homeslider1 />

          <div class="section section-padding boardcast" id="broad_cast">
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  Jai Sriram Jai Jai Sriram
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
                {/* <h4 class="title"></h4> */}
              </div>
              <div class="row sigma_broadcast-video">
                <div class="col-12 mb-5">
                  <div class="row g-0 align-items-center">
                    <div class="col-lg-6">
                      <div class="sigma_video-popup-wrap">
                        <img src={Carousals} alt="video" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="sigma_video-popup-wrap">
                        <img src={Carousals1} alt="video" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section class="section" id="about_us">
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  About Us
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-6 mb-lg-30">
                  <div class="img-group">
                    <div class="img-group-inner">
                      <img
                        src={rama}
                        alt="about"
                        style={{ height: "530px", width: "100%" }}
                      />
                      <span></span>
                      <span></span>
                    </div>
                    <img
                      src="assets/img/banner/blue-gradient-background.jpg"
                      alt="about"
                    />
                    <img
                      src="assets/img/banner/blue-gradient-background.jpg"
                      alt="about"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="me-lg-30">
                    <div class="section-title mb-0 text-start">
                      <h4 class="title"> Mera Bharat Mahaan...</h4>
                    </div>
                    <p class="blockquote" style={{color:"#3D5E95"}}>
                      Prince of Ayodhya: Rama was born to King Dasharatha and
                      Queen Kaushalya in the city of Ayodhya. He is often
                      referred to as "Maryada Purushottama," which means the
                      perfect or ideal man who strictly adheres to dharma
                      (righteousness). Exemplary Virtues: Rama is celebrated for
                      his impeccable character and adherence to moral and
                      ethical principles. His life is often considered a model
                      for righteous living, and he is revered as an embodiment
                      of virtue, humility, and compassion. Heroic Exploits: The
                      most well-known episode from Rama's life is his quest to
                      rescue his wife, Sita, who was abducted by the demon king
                      Ravana. This forms the central narrative of the Ramayana,
                      showcasing Rama's bravery, intelligence, and unwavering
                      commitment to duty.
                    </p>
                    <NavLink to="/about">
                      <a href="" class="sigma_btn-custom light">
                        Learn More <MDBIcon icon="arrow-right" />
                      </a>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Service /> */}

          <div
            class="section section-padding copntact_us_padding"
            id="services_info"
          >
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  FESTIVALS
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <a class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <img src={temple} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Vykuntha Ekadasi</h5>
                      <p>
                        A religious festival that attracts lakhs(hundred
                        thousand) of devotees from all over the country
                      </p>
                    </div>
                    <span class="btn-link">
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                    </span>
                  </a>
                </div>

                <div class="col-lg-4 col-md-6">
                  <a href="" class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <i class="flaticon-hindu-2"></i>
                      <img src={hindu} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Vaaggeyakaara Mahotsavams</h5>
                      <p>
                        Vaaggeyakara Mahotsavams are being celebrated every year
                      </p>
                    </div>
                    <span class="btn-link">
                      Learn More <MDBIcon icon="arrow-right" />
                    </span>
                  </a>
                </div>

                <div class="col-lg-4 col-md-6">
                  <a href="" class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <i class="flaticon-pooja"></i>
                      <img src={pooja} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Sri Rama Navami Brahmotsavams</h5>
                      <p>Another major festival, Sri Rama Navami </p>
                    </div>
                    <span class="btn-link">
                      Learn More <MDBIcon icon="arrow-right" />{" "}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="section section-padding" id="testimonial">
            <div class="container">
            <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  GALLERY
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="portfolio-filter row">
                <div class="col-lg-4 coaching">
                  <div class="sigma_portfolio-item style-2">
                    <img src={temp1} alt="portfolio" style={{height:"240px"}} />
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{color:"#3D5E95"}}>
                          Parasuraamaavataram{" "}
                          </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon icon="arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 strategy">
                  <div class="sigma_portfolio-item style-2">
                    <img src={temp2} alt="portfolio"  style={{height:"240px"}}/>
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{color:"#3D5E95"}}> Varaahaavataram </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon icon="arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 coaching strategy">
                  <div class="sigma_portfolio-item style-2">
                    <img src={temp3} alt="portfolio"  style={{height:"240px"}}/>
                    <div class="sigma_portfolio-item-content">
                      <div class="sigma_portfolio-item-content-inner">
                        <h5>
                          {" "}
                          <a href="" style={{color:"#3D5E95"}}>Sree rama navami kalyanam </a>{" "}
                        </h5>
                      </div>
                      <a href="">
                        <MDBIcon icon="arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal show={lgShow} onHide={() => setLgShow(false)} >
              <Modal.Header closeButton style={{background:"#911c1c"}}></Modal.Header>
              <Modal.Body  style={{background:"#911c1c"}}>
                {" "}
                <Row>
                  <div className="modelsfont">
                    <Col md="12">
                      <h4
                        className="text-center text-warning modelsfont"
                        style={{ textDecoration: "underline" }}
                      >
                        Temple Opening Invitation
                      </h4>
                      <h6
                        className="text-center text-warning modelsfont"
                        style={{ textDecoration: "underline" }}
                      >
                        Sunday, 11th February 2024, 9 am Onwords{" "}
                      </h6>
                      <p className="text-center text-white modelsfont">
                        The radiant joy celebration of Brahmas Dharmashala
                        Foundation's newly built Hanuman temple opening
                      </p>
                    </Col>
                    <div
                      style={{
                        borderTop: "1px solid white",
                        borderBottom: "1px solid white",
                      }}
                    >
                      <Row className="pt-2 pb-3">
                        <Col
                          style={{
                            borderRight: "1px solid white",
                          }} md={6} sm={12} className="mb-2 mt-2"
                        >
                          {" "}
                          <img
                            src={Carousals5}
                            alt=""
                            style={{ width: "100%", height: "350px" }}
                          />
                        </Col>
                        <Col md={6} sm={12}  >
                          <img
                            src={Carousalss}
                            alt=""
                            style={{ width: "100%", height: "350px" }} className="mb-2 mt-2"
                          />
                        </Col>
                      </Row>
                    </div>
                    <Col md="12">
                      <h6
                        className="text-center text-warning pt-3 modelsfont"
                        style={{ textDecoration: "underline" }}
                      >
                        *Venue*
                      </h6>
                      <p className="text-center text-white modelsfont">
                        UppalaPadu village,{" "}
                      </p>
                      <p className="text-center text-white modelsfont">
                        Dagadarthi Mandal, Nellore, Andhra Pradesh, India.
                      </p>
                      <h6
                        className="text-center text-warning modelsfont"
                        style={{ textDecoration: "underline" }}
                      >
                        *Invited By*
                      </h6>
                      <p className="text-center text-white modelsfont">
                        Sri Brahma Bhaskar Rao - Smt Kavitha Brahma
                      </p>
                    </Col>
                  </div>
                </Row>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <div
              class="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
              id="contact_info"
              style={{ backgroundImage: `url(${bg1})` }}
            >
              <div class="container">
                <div class="section-title text-center">
                  <p class="subtitle" style={{ color: "#fff" }}>
                    <MDBIcon icon="om" size="10px" className="mr-3 " />
                    contact Us
                    <MDBIcon icon="om" size="1x" className="ml-3" />
                  </p>
                </div>

                <div class="row align-items-center">
                  <div class="col-lg-6 mb-lg-30">
                    <form method="post">
                      <div class="form-row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <MDBIcon icon="user-alt" />
                            <input
                              type="text"
                              class="form-control transparent"
                              placeholder="Full Name"
                              name="fname"
                              value=""
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <MDBIcon icon="user-alt" />
                            <input
                              type="text"
                              class="form-control transparent"
                              placeholder="Mobile Number"
                              name="lname"
                              value=""
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <textarea
                              name="message"
                              class="form-control transparent"
                              placeholder="Enter Location"
                              rows="2"
                              required
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <textarea
                              name="message"
                              class="form-control transparent"
                              placeholder="Enter Message"
                              rows="2"
                              required
                            ></textarea>
                          </div>
                        </div>

                        {/* <div class="col-lg-6">
                          <div class="form-group">
                            {" "}
                            <div className="col mt-3">
                              <LoadCanvasTemplate />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control transparent"
                              placeholder="Capta"
                              name="lname"
                              value=""
                              required
                            />
                          </div>
                        </div> */}
                        <div class="col-lg-12">
                          <button
                            type="submit"
                            class="sigma_btn-custom "
                            name="button"
                             id="ld"
                          >
                            {" "}
                            Submit
                            <MDBIcon icon="arrow-right" />{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-lg-6">
                    <div class="">
                      <iframe
                        src="https://maps.google.com/maps?q=uppalapadu+village+nellore+district&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        width="550"
                        height="330"
                        style={{ border: "0", borderRadius: "20px" ,width:'100%'}}
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
