import React, { useState, useEffect } from "react";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import contact from "../assets/img/banner/contact-banner.jpeg";
import { MDBIcon } from "mdbreact";
import email from "../assets/img/new/email.png";
import call from "../assets/img/new/call.png";
import location from "../assets/img/new/location.png";
import Header1 from "../Component/Header1";
import bg1 from "../assets/img/bg1.jpg";
import Load from "../assets/gif.gif";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);

    return () => clearInterval(timer);
  }, []);

  // useEffect(() => {
  //   loadCaptchaEnginge(8);
  // }, []);

  // const doSubmit = () => {
  //   let user_captcha = document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha) === true) {
  //     alert("Captcha Matched");
  //     loadCaptchaEnginge(6);
  //     document.getElementById("user_captcha_input").value = "";
  //   } else {
  //     alert("Captcha Does Not Match");
  //     document.getElementById("user_captcha_input").value = "";
  //   }
  // };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} style={{ height: "200px" }}></img>
          </div>
        </>
      ) : (
        <>
          <Header1 />

          <div
            class="sigma_subheader dark-overlay dark-overlay-2 pb-4 mb-4"
            style={{ backgroundImage: `url(${contact})` }}
          >
            <div class="container mt-5">
              <div class="sigma_subheader-inner">
                <div class="sigma_subheader-text">
                  <h1>Contact Us</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item mr-3">
                      <a class="btn-link">
                        <NavLink to="/">
                          <span style={{ color: "#911c1c" }}>Home</span>
                        </NavLink>{" "}
                      </a>
                    </li>
                    <MDBIcon icon="om" size="10px" className="mr-3 " />
                    <li class="breadcrumb-item active" aria-current="page">
                      <a class="btn-link">
                        <NavLink to="/services">
                          <span style={{ color: "#911c1c" }}>Contact US</span>
                        </NavLink>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div class="section section-padding copntact_us_padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <div class="sigma_icon-block text-center light icon-block-7">
                    <i class="flaticon-email"></i>
                    <div class="sigma_icon-block-content">
                      <span>
                        Send Email <MDBIcon icon="arrow-right" />
                      </span>
                      <h5 style={{color:"#3D5E95"}}>  Email Address</h5>
                      <p>info@brahmasdharamasalafoundation</p>
                    </div>
                    <div class="icon-wrapper" style={{ color: "red" }}>
                      <img
                        src={email}
                        style={{
                          width: "30px",
                          marginTop: "2.4rem",
                          color: "red",
                        }}
                      />
                      <i class="flaticon-email"></i>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="sigma_icon-block text-center light icon-block-7">
                    <i class="flaticon-call"></i>
                    <div class="sigma_icon-block-content">
                      <span >
                        Call Us Now <MDBIcon icon="arrow-right" />{" "}
                      </span>
                      <h5 style={{color:"#3D5E95"}}> Phone Number </h5>
                      <p>+91 9494613601 </p>
                    </div>
                    <div class="icon-wrapper">
                      <img
                        src={call}
                        style={{ width: "30px", marginTop: "2.4rem" }}
                      />
                      <i class="flaticon-call"></i>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="sigma_icon-block text-center light icon-block-7">
                    <i class="flaticon-location"></i>
                    <div class="sigma_icon-block-content">
                      <span >
                        Find Us Here <MDBIcon icon="arrow-right" />{" "}
                      </span>
                      <h5 style={{color:"#3D5E95"}}> Location </h5>
                      <p>Uppalapadu , Dagadarthi, Nellore - 522509..</p>
                    </div>
                    <div class="icon-wrapper">
                      <img
                        src={location}
                        style={{ width: "30px", marginTop: "1rem" }}
                      />
                      <i class="flaticon-location"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
            id="contact_info"
            style={{ backgroundImage: `url(${bg1})` }}
          >
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle" style={{ color: "#fff" }}>
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  contact Us
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>

              <div class="row align-items-center">
                <div class="col-lg-6 mb-lg-30">
                  <form method="post">
                    <div class="form-row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <MDBIcon icon="user-alt" />
                          <input
                            type="text"
                            class="form-control transparent"
                            placeholder="Full Name"
                            name="fname"
                            value=""
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <MDBIcon icon="user-alt" />
                          <input
                            type="text"
                            class="form-control transparent"
                            placeholder="Mobile Number"
                            name="lname"
                            value=""
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <textarea
                            name="message"
                            class="form-control transparent"
                            placeholder="Enter Location"
                            rows="2"
                            required
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <textarea
                            name="message"
                            class="form-control transparent"
                            placeholder="Enter Message"
                            rows="2"
                            required
                          ></textarea>
                        </div>
                      </div>

                      {/* <div class="col-lg-6">
                        <div class="form-group">
                          {" "}
                          <div className="col mt-3">
                            <LoadCanvasTemplate />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control transparent"
                            placeholder="Capta"
                            name="lname"
                            value=""
                            required
                          />
                        </div>
                      </div> */}
                      <div class="col-lg-12">
                        <button
                          type="submit"
                          class="sigma_btn-custom"
                          name="button"
                          id="ld"
                        >
                          {" "}
                          Submit
                          <MDBIcon icon="arrow-right" />{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <iframe
                      src="https://maps.google.com/maps?q=uppalapadu+village+nellore+district&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      width="550"
                      height="330"
                      style={{ border: "0", borderRadius: "20px" ,width:'100%'}}
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Contact;
