import React from "react";
import Header1 from "../Component/Header1";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import ayodya from "../assets/img/temples/ayodya-temple.jpg";
import { Link, NavLink } from "react-router-dom";
import subheader from "../assets/img/subheader.jpg";
import { MDBIcon } from "mdbreact";
import Slider from "react-slick";
import Carousal1 from "../assets/img/banner/banner2.jpg";
import Carousal4 from "../assets/img/banner/banner4.jpg";
import Carousal5 from "../assets/img/banner/banner5.jpg";

const Templedetails = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 4,
    // slidesToScroll: ,
    arrows: false,
    // width: 500,
    // responsive: [
    //     {
    //         breakpoint: 767,
    //         settings: {
    //             arrows: false,

    //             slidesToShow: 5,
    //         },
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             arrows: false,
    //             slidesToShow: 5,
    //         },
    //     },
    // ],
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <div>
      <Header1 />
      {/* <Navbar /> */}
      <div
        class="sigma_subheader dark-overlay dark-overlay-2 pb-4 mb-4"
        style={{ backgroundImage: `url(${subheader})` }}
      >
        <div class="container mt-5">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>Gallery</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item mr-3">
                  <a class="btn-link">
                    <NavLink to="/">
                      <span style={{ color: "#911c1c" }}>Home</span>
                    </NavLink>{" "}
                  </a>
                </li>
                <MDBIcon icon="om" size="10px" className="mr-3 " />
                <li class="breadcrumb-item active" aria-current="page">
                  <a class="btn-link">
                    <NavLink to="/projects">
                      <span style={{ color: "#911c1c" }}>Gallery</span>
                    </NavLink>
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="entry-content">
            <div class="sigma_post-single-thumb">
              <img src={ayodya} alt="post" />
              {/* <div class="sigma_sermon-box">
                <div class="sigma_box">
                  <span class="subtitle">Latest Posts</span>
                  <h4 class="title mb-0">
                    <a href="#">Festival Ayodhya</a>
                  </h4>
                  <ul class="sigma_sermon-info mb-0">
                    <li>
                      <i class="far fa-user"></i>
                      Message From
                      <a href="#" class="ms-2">
                        <u>Koteswararao</u>
                      </a>
                    </li>
                    <li class="mt-0 ms-4">
                      <i class="far fa-calendar-check"></i>
                      Dec 12, 2021
                    </li>
                  </ul>
                  <ul class="sigma_sm square">
                    <li>
                      <a href="https://www.youtube.com/embed/Ok4tpqvmHss">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" download>
                        <i class="far fa-file-pdf"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fas fa-share-alt"></i>
                      </a>
                    </li>
                  </ul>
                  <div class="sigma_audio-player">
                    <div class="audio-wrapper" id="player-container">
                      <audio
                        type="audio/ogg"
                        src="https://bhadrachalarama.org/audio/Ramadasu_Keerthanalu_1/Idhigo_Bhadradri_Gowthami_adigo_chudandi.mp3"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <p>
              Ram Mandir in Ayodhya has always been prominent to Hindus as it is
              said to be the ‘Ram Janam Bhoomi‘, the birth place of Lord Ram.
              Located on the banks of River Saryu in the district of Faizabad,
              this Ram Mandir holds great importance among Hindus. Every year
              thousands of devotees are magnetized to this divine land to get a
              glimpse of the place where their ideal king Shri Ram was born.
              Tranquil Ghats, quintessential temples and unbeatable belief of
              Hindus in Lord Ram keep the serenity of Ram Mandir in Ayodhya
              alive
            </p>

            {/* <div class="sigma_box pb-0 m-0">
              <div class="row">
                <div class="col-md-4">
                  <div class="sigma_icon-block icon-block-3">
                    <div class="icon-wrapper">
                      <i class="flaticon-temple"></i>
                    </div>
                    <div class="sigma_icon-block-content">
                      <h5> Temple </h5>
                      <p>
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="sigma_icon-block icon-block-3">
                    <div class="icon-wrapper">
                      <i class="flaticon-powder"></i>
                    </div>
                    <div class="sigma_icon-block-content">
                      <h5> Details </h5>
                      <p>
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="sigma_icon-block icon-block-3">
                    <div class="icon-wrapper">
                      <i class="flaticon-arti"></i>
                    </div>
                    <div class="sigma_icon-block-content">
                      <h5> Puja </h5>
                      <p>
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* 
      <section>
        <div class="container">
          <div class="section-title text-start">
            <h4 class="subtitle">Videos</h4>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <Slider {...settings} style={{ width: "100%" }}>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">Jai Shriram TV</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal4} />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">
                      The marriage of Sri Ramu is Vaibhogam
                    </h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal5} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">The uniqueness of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">Sita's personality is ideal today</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">Distinctive history of Sri Rama</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal4} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal5} alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=UV0Y1UQ0PHY"
                        class="sigma_video-popup popup-sm popup-youtube"
                      >
                        <i class="fas fa-play"></i>
                      </a>
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section style={{ marginTop: "-40px" }}>
        <div class="container ">
          <div class="section-title text-start">
            <h4 class="subtitle">Images</h4>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <Slider {...settings} style={{ width: "100%" }}>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                  
                    </div>
                    <h6 class="mb-0 mt-3">Jai Shriram TV</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal4} />
            
                    </div>
                    <h6 class="mb-0 mt-3">
                      The marriage of Sri Ramu is Vaibhogam
                    </h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal5} alt="video" />
                      
                    </div>
                    <h6 class="mb-0 mt-3">The uniqueness of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                  
                    </div>
                    <h6 class="mb-0 mt-3">Sita's personality is ideal today</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                    
                    </div>
                    <h6 class="mb-0 mt-3">Distinctive history of Sri Rama</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal1} alt="video" />
                      
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal4} alt="video" />
                   
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                  <div>
                    <div class="sigma_video-popup-wrap">
                      <img src={Carousal5} alt="video" />
                     
                    </div>
                    <h6 class="mb-0 mt-3">The story of Sri Ramu</h6>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default Templedetails;
