import React from "react";
import Carousal1 from "../assets/img/banner/3.jpg";
import Carousal4 from "../assets/img/banner/banner3.jpeg";
import Carousel from "react-bootstrap/Carousel";
import Carousal5 from "../assets/img/banner/banner2.jpeg";

const Homeslider1 = () => {
  return (
    <div>
      <Carousel fade className="jss">
        <Carousel.Item>
          <img
            className="d-block w-100 jkl"
            src={Carousal1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3 className="lsd"> Jai Sriram Jai Jai Sriram</h3>
            <p style={{ color: "black" }} className="lsds">
              "Brahmas Dharamasala Foundation"{" "}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 jkl"
            src={Carousal4}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 jkl"
            src={Carousal5}
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Homeslider1;
