import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
// import { Button, Navbar,Container,Nav} from 'react-bootstrap';
// import "../../public/assets"
import "../App.css";
// import "../css/Common.css"
import logo from "../assets/img/logo/logo.png";
import { MDBIcon } from "mdbreact";
import { slide as Menu } from "react-burger-menu";
import SideNav, {
  Toggle,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

const Header = () => {
  const showSettings = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Navbar expand="lg " className="fixed-top bg-white">
        <div onClick={showSettings}>
          <div class="sigma_header-controls style-2 arowhide">
            <ul class="sigma_header-controls-inner">
              <li class="aside-toggler style-2 aside-trigger-right desktop-toggler">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </li>
            </ul>
          </div>
        </div>

        <Nav className="me-auto my-2 my-lg-0 navbar-nav" navbarScroll>
          <li class="menu-item active">
            <a className="navbar">
              <NavLink to="/" active>
                HOME
              </NavLink>
            </a>
          </li>
          <li class="menu-item">
            <a className="navbar">
              <NavLink to="/about">ABOUT US</NavLink>
            </a>
          </li>

          <li class="menu-item">
            <a className="navbar">
              <NavLink to="/services"> Fairs and festivals</NavLink>
            </a>
          </li>

          <li class="menu-item">
            <a className="navbar">
              <NavLink to="/contact-us">CONTACT US</NavLink>
            </a>
          </li>
        </Nav>

        <div className="arowhide">
          <a id="play-video" class="video-play-button">
            Live
          </a>
        </div>

        <div class="arowhide">
          <a class="navbar-brand">
            <NavLink to="/" active>
              <img src={logo} alt="logo" />
            </NavLink>
          </a>
        </div>

        <div class="dropdown drop-down arowhide">
          <button
            class="btn btn dropdown-toggle "
            type="button"
            id="dropDown"
            data-toggle="dropdown"
            aria-expanded="false"
            style={{ borderRadius: "30px" }}
          >
            <span class="drop-down__name">
              <i class="fa fa-user" aria-hidden="true"></i>{" "}
            </span>
          </button>

          <div
            class="dropdown-menu "
            aria-labelledby="dropdownMenuButton"
            style={{ marginLeft: "-7rem", marginTop: "2rem" }}
          >
            <ul className="drop-down__menu">
              <li class="dropdown-item drop-down__item" href="#">
                <NavLink to="/temples">
                  Temples{" "}
                  <span style={{ marginLeft: "90px" }}>
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </span>
                </NavLink>
              </li>
              <li class="dropdown-item drop-down__item" href="#">
                {" "}
                <NavLink to="/addTemple">
                  Add Temples{" "}
                  <span style={{ marginLeft: "60px" }}>
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </span>
                </NavLink>
              </li>
              <li class="dropdown-item drop-down__item" href="#">
                {" "}
                <NavLink to="/password">
                  {" "}
                  Change Password{" "}
                  <span style={{ marginLeft: "2rem" }}>
                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                  </span>
                </NavLink>
              </li>
              <li class="dropdown-item drop-down__item" href="#">
                Log Out{" "}
                <span style={{ marginLeft: "100px" }}>
                  <i class="fa fa-database" aria-hidden="true"></i>
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>

        {/* <SideNav
    onSelect={(selected) => {
        // Add your code here
    }}
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="home">
            <NavIcon>
                <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
                Home
            </NavText>
        </NavItem>
        <NavItem eventKey="charts">
            <NavIcon>
                <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
                Charts
            </NavText>
            <NavItem eventKey="charts/linechart">
                <NavText>
                    Line Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Bar Chart
                </NavText>
            </NavItem>
        </NavItem>
    </SideNav.Nav>
</SideNav> */}

        {/* <div style={{marginTop:"-6rem"}}>
 <Menu  width={400} >
   <aside class="sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg"  >
    <div class="sidebar">
      <div class="sidebar-widget widget-logo">
        <a href="index.html"><img src={logo} class="mb-30" alt="img"/></a>
        <p>It is not about an individual or a group. Slogan about the Indian soul. It is common for everyone to glorify
          themselves as a rare god whom they consider to be their stranger....</p>
      </div>
      <div class="sidebar-widget widget-ig">
        <h5 class="widget-title">Instagram</h5>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-6">
            <a href="#" class="sigma_ig-item">
              <img src="assets/img/ig/4.jpg" alt="ig"/>
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-6">
            <a href="#" class="sigma_ig-item">
              <img src="assets/img/ig/5.jpg" alt="ig"/>
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-6">
            <a href="#" class="sigma_ig-item">
              <img src="assets/img/ig/6.jpg" alt="ig"/>
            </a>
          </div>
        </div>
      </div>
   
      <div class="sidebar-widget">
        <h5 class="widget-title">Follow Us</h5>
        <div class="sigma_post-share">
          <ul class="sigma_sm square light">
            <li>
              <a href="https://www.facebook.com/" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/home" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/" target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </aside>
      
      </Menu>

 </div> */}
      </Navbar>
    </>
  );
};

export default Header;
