import "../src/css/Common.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "../src/Component/Home";
import About from "../src/Component/About";
import Projucts from "../src/Component/Projucts";
import Services from "../src/Component/Service";
import Contact from "../src/Component/Contact";
import AddTemple from "../src/Component/AddTemple";
import ChangePsw from "../src/Component/ChangePsw";
import Login from "../src/Component/Login";
import Temples from "../src/Component/Temples";
import Templedetails from "../src/Component/Templedetails";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Header1 from "../src/Component/Header1";
import Homeslider1 from "../src/Component/Homeslider1";
import ScrollTop from "./ScrollTop";

function App() {
  return (
    <>
      <ScrollTop></ScrollTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Projucts />} />
        <Route path="/festival" element={<Services />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/addTemple" element={<AddTemple />} />
        <Route path="/password" element={<ChangePsw />} />
        <Route path="/login" element={<Login />} />
        <Route path="/temples" element={<Temples />} />
        <Route path="/templedetails" element={<Templedetails />} />
        <Route path="/Header1" element={<Header1 />} />
        <Route path="/Homeslider1" element={<Homeslider1 />} />
      </Routes>
    </>
  );
}

export default App;
