import React from "react";
import Header from "../Component/Header";
import logo from "../assets/img/logo/logo.png";
import maharastra from "../assets/img/temples/maharastra.jpg";
import ayodya from "../assets/img/temples/ayodya-temple.jpg";
import amrutaser from "../assets/img/temples/amrutaser.jpg";
import { Link, NavLink } from "react-router-dom";
import { MDBIcon } from "mdbreact";

const AddTemple = () => {
  return (
    <>
      <Header />

      <hr style={{ marginTop: "50px" }} />
      <section class="temple_addform">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="responsive_form">
                <form className="mb-5">
                  <h3>Add Temple Details</h3>
                  <label for="title">Title</label>
                  <input type="text" class="form-control" />
                  <label for="address">Address</label>
                  <input type="text" class="form-control" />
                  <label for="file">Images Upload</label>
                  <span class="fit button">
                    <input type="file" />
                  </span>
                  <br />
                  <label for="textarea">Description</label>
                  <textarea
                    name="description"
                    id=""
                    cols="10"
                    rows="5"
                    class="form-control"
                    style={{ height: "5rem" }}
                  ></textarea>
                  <button type="submit" class="custom-btn">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="add_row">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Enter Temple Details</th>
                    <th>Description</th>
                    <th>
                      <span class="addBtn">
                        <i class="fa fa-plus"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="addRow">
                    <td class="col-xs-3">
                      <input
                        class="form-control addMain"
                        type="text"
                        placeholder="Enter title"
                      />
                    </td>

                    <td class="col-xs-3">
                      <input
                        class="form-control addPrefer"
                        type="text"
                        placeholder="Enter title"
                      />
                    </td>
                    <td class="col-xs-5">
                      <input
                        class="form-control addCommon"
                        type="text"
                        placeholder="Enter title"
                      />
                    </td>
                    <td class="col-xs-1 text-center"></td>
                  </tr>
                  <tr>
                    <td class="col-xs-3">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />
                    </td>

                    <td class="col-xs-3">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />
                    </td>
                    <td class="col-xs-5">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />{" "}
                    </td>
                    <td class="col-xs-1 text-center">
                      <a onClick="deleteRow(this)" className="btn btn">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-xs-3">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />
                    </td>

                    <td class="col-xs-3">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />
                    </td>
                    <td class="col-xs-5">
                      <input
                        type="text"
                        value=""
                        class="form-control editable"
                      />{" "}
                    </td>
                    <td class="col-xs-1 text-center">
                      <a onClick="deleteRow(this)" className="btn btn">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <footer class="sigma_footer footer- 2">
        <div class="sigma_footer-middle">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer-widget">
                <h5 class="widget-title">Jai SriRam</h5>
                <p class="mb-4">
                  Jai Sriram TV is a platform for Indian across the globe giving
                  news and views which spreads like air in the environment using
                  technology at par with others in the field of Journalism. We
                  reflect the views of common man, ordinary citizens on a global
                  platform.
                </p>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center">
                  <i class="far fa-phone custom-primary me-3"></i>
                  <a href="tel:+91 9494613601">
                    <span>+91 9494613601</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                  <i class="far fa-envelope custom-primary me-3"></i>
                  <a href="mailto:info@brahmasdharamasalafoundation">
                    <span>info@brahmasdharamasalafoundation</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2 mobile_mail">
                  <i class="far fa-map-marker custom-primary me-3"></i>
                  <span>
                    Jai Sriram Foundation. #193, HACA Bhavan, Adarsh Nagar,
                    Opp.Telangana State Assembly, Hyderabad - 500004, India.
                  </span>
                </div>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12 footer-widget">
                <h5 class="widget-title">Links</h5>
                {/* <ul>
           <li class="active"> <a>Home</a> </li>
           <li> <a href="about-us.html">About Us</a> </li>
           <li> <a href="project.html">Projects</a> </li>
           <li> <a href="services.html">Services</a> </li>
           <li> <a href="contact-us.html">Contact Us</a> </li>
           <li> <a href="broadcast.html">broadcast</a> </li>
         </ul> */}
                <ul>
                  <li class="active">
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">ABOUT US</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects">PROJUCTS</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">SERVICES</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">CONTACT US</NavLink>
                  </li>
                </ul>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
                <h5 class="widget-title">Recent Projects</h5>
                <article class="sigma_recent-post">
                  <a>
                    <img src={maharastra} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>
                          Temple companies are being so transparent with their
                          work
                        </span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
                <article class="sigma_recent-post">
                  <a>
                    <img src={ayodya} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>Testimony love offering so blessed</span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
                <article class="sigma_recent-post">
                  <a>
                    <img src={amrutaser} alt="post" />
                  </a>
                  <div class="sigma_recent-post-body">
                    <a>
                      {" "}
                      <i class="far fa-calendar"></i> May 20, 2022
                    </a>
                    <h6>
                      {" "}
                      <a>
                        <span>
                          As we've all discovered by now, the world can change
                        </span>
                      </a>{" "}
                    </h6>
                  </div>
                </article>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="bottm_footer">
                    <div class="sigma_footer-copyright">
                      <p>
                        <a
                          href="https://www.digitalraiz.com/"
                          target="_blank"
                          class="custom-primary"
                        >
                          <span>Copyright © 2021</span>
                          DigitalRaiz <span>All Rights Reserved</span>
                        </a>{" "}
                      </p>
                    </div>
                    <div
                      class="sigma_footer-logo"
                      style={{ marginLeft: "10rem" }}
                    >
                      <img src={logo} alt="logo" />
                    </div>
                    <ul class="sigma_sm square" style={{ marginLeft: "15rem" }}>
                      <li>
                        <a href="https://www.facebook.com/" target="_blank">
                          <MDBIcon fab icon="facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/" target="_blank">
                          <MDBIcon fab icon="linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/home" target="_blank">
                          <MDBIcon fab icon="twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/" target="_blank">
                          <MDBIcon fab icon="youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="jsScroll" class="scroll" onclick={()=>scrollTo()}>
   <MDBIcon icon="angle-up" />
   </div> */}
        </div>
      </footer>
    </>
  );
};

export default AddTemple;
