import React, { useState } from 'react'
import "../assets/css/form.css"
import { Link, NavLink } from 'react-router-dom';
import logo from "../assets/img/logo/logo.png";

const ChangePsw = () => {

    const [show, setshow] = useState(true)
    const [show1, setshow1] = useState(true)

    return (
        < >
            <div class="section1">
                <div class="container1" id="container1">
                    <div class="form-container1 sign-in-container1">
                        <form>
                            <a href="#"><NavLink to="/"><img src={logo} alt="logo"/></NavLink></a>
                            <h2>Forgot Password</h2>
                            <input type="email" placeholder="Email Or Mobile" />
                            <span class="remember"> Are you Remember The Password <a class="forgot" > <NavLink to="/login">Log In</NavLink></a></span>
                            <button onclick="return false;">Submit</button>

                        </form>
                    </div>
                   {
                       show? <div class="form-container1 sign-up-container1">
                       <form>
                           <a href="#"><NavLink to="/"><img src={logo} alt="logo"/></NavLink></a>
                           <h2>OTP Verfication</h2>
                           <p>Please enter the one time password to verify your account</p>
                           <p>A code has been sent to *******9897</p>
                           <a href="#">Change the Mobile Number</a>
                           <div class="validate_inputs">
                               <input type="otp-1" />
                               <input type="otp-2" />
                               <input type="otp-3" />
                               <input type="otp-4" />
                               <input type="otp-5" />
                               <input type="otp-6" />
                           </div>
                           <a href="#">Resend OTP</a>
                           <button onclick="return false;">Validate</button>
                       </form>
                   </div>:null
                   }
                    <div class="overlay-container1">
                        <div class="overlay">
                            <div class="overlay-panel overlay-left">
                                {/* <!-- <a href="index.html"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                            Back To Home</a> --> */}
                                <h1>Welcome Back!</h1>
                                <button class="ghost" id="signIn">Forgot Password</button>

                            </div>
                            <div class="overlay-panel overlay-right">

                                <h1>Hello, Friend!</h1>
                                <p>Enter your personal details and start your journey with us</p>
                                <button class="ghost" id="signUp" onClick={()=>setshow(true)}>OTP Validate </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePsw