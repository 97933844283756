import React, { useState, useEffect } from "react";
import { MDBIcon } from "mdbreact";
import temple from "../assets/img/new/temple.png";
import hindu from "../assets/img/new/hindu.png";
import pooja from "../assets/img/new/pooja.png";
import Load from "../assets/gif.gif";
import Footer from "../Component/Footer";
import Header1 from "../Component/Header1";
import subheader from "../assets/img/subheader.jpg";
import { NavLink } from "react-router-dom";

const Service = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);

    return () => clearInterval(timer);
  }, []);
  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} style={{ height: "200px" }}></img>
          </div>
        </>
      ) : (
        <>
        <Header1 />
        <div
        class="sigma_subheader dark-overlay dark-overlay-2 pb-4 mb-4"
        style={{ backgroundImage: `url(${subheader})` }}
      >
        <div class="container mt-5">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>FESTIVALS</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item mr-3">
                  <a class="btn-link">
                    <NavLink to="/">
                      <span style={{ color: "#911c1c" }}>Home</span>
                    </NavLink>{" "}
                  </a>
                </li>
                <MDBIcon icon="om" size="10px" className="mr-3 " />
                <li class="breadcrumb-item active" aria-current="page">
                  <a class="btn-link">
                    <NavLink to="/festival">
                      <span style={{ color: "#911c1c" }}>FESTIVALS</span>
                    </NavLink>
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
          <div
            class="section section-padding copntact_us_padding"
            id="services_info"
          >
            <div class="container">
              <div class="section-title text-center">
                <p class="subtitle">
                  <MDBIcon icon="om" size="10px" className="mr-3 " />
                  FESTIVALS
                  <MDBIcon icon="om" size="1x" className="ml-3" />
                </p>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <a class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <img src={temple} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Vykuntha Ekadasi</h5>
                      <p>
                        A religious festival that attracts lakhs(hundred
                        thousand) of devotees from all over the country
                      </p>
                    </div>
                    <span class="btn-link">
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true " ></i>{" "}
                    </span>
                  </a>
                </div>

                <div class="col-lg-4 col-md-6">
                  <a href="" class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <i class="flaticon-hindu-2"></i>
                      <img src={hindu} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Vaaggeyakaara Mahotsavams</h5>
                      <p>
                        Vaaggeyakara Mahotsavams are being celebrated every year
                      </p>
                    </div>
                    <span class="btn-link">
                      Learn More <MDBIcon icon="arrow-right" />
                    </span>
                  </a>
                </div>

                <div class="col-lg-4 col-md-6">
                  <a href="" class="sigma_service style-1">
                    <div class="sigma_service-thumb">
                      <i class="flaticon-pooja"></i>
                      <img src={pooja} style={{ width: "70px" }} />
                    </div>
                    <div class="sigma_service-body">
                      <h5>Sri Rama Navami Brahmotsavams</h5>
                      <p>Another major festival, Sri Rama Navami </p>
                    </div>
                    <span class="btn-link">
                      Learn More <MDBIcon icon="arrow-right" />{" "}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Service;
