import React from 'react'
import "../assets/css/form.css"
import { Link, NavLink } from 'react-router-dom';
import logo from "../assets/img/logo/logo.png";

const Login=()=> {
  return (
    <>
           <div class="section1">
        <div class="container1" id="container1">
            <div class="form-container1 sign-up-container1">
                <form>
                    
                    <a href=""><NavLink to="/"><img src={logo} alt="logo"/></NavLink></a>
                    <h2>Sign Up</h2>
                    <input type="text" placeholder="Full Name" />
                    <input type="text" placeholder="Mobile Number" />
                    <input type="email" placeholder="Email" />
                    <div>
                        <input type="district" placeholder="District" />
                        <input type="state" placeholder="state" />
                        <input type="city" placeholder="city" />
                    </div>
                    <input type="address" placeholder="Address" />
                    <button onclick="return false;">Sign Up</button>

                </form>
            </div>
            <div class="form-container1 sign-in-container1">
                <form>
                    <a href="#"><NavLink to="/"><img src={logo} alt="logo"/></NavLink></a>
                    <h2>Sign In</h2>
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <a href="forgot.html" ><NavLink to="/password">Forgot your password?</NavLink></a>
                    <button onclick="return false;">Sign In</button>
                </form>
            </div>
            <div class="overlay-container1">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">
                        {/* <!-- <a href="index.html"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                            Back To Home</a> --> */}
                        <h1>Welcome Back!</h1>
                        <p>Please login with your personal info</p>
                        <button class="ghost" id="signIn">Sign In</button>

                    </div>
                    <div class="overlay-panel overlay-right">
                        {/* <!-- <a href="index.html"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                            Back To Home</a> --> */}
                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start your journey with us</p>
                        <button class="ghost" id="signUp">Sign Up</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Login